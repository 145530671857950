//src/CurrencyRates.js
import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import ReactCountryFlag from "react-country-flag";
import { fetchCurrencyRates } from './actions';

function countryCodeToFlag(currencyCode) {
  const currencyToCountryCode = { '840': 'US', '978': 'EU', '985': 'PL', '980': 'UA' };
  const countryCode = currencyToCountryCode[currencyCode];
  return countryCode ? (
    <ReactCountryFlag
      countryCode={countryCode}
      svg
      style={{ width: '2em', height: '2em' }}
      title={countryCode}
    />
  ) : '';
}

const CurrencyRates = () => {
  const dispatch = useDispatch();
  const { rates, loading, error } = useSelector(state => state.currencyRates);

  useEffect(() => {
    dispatch(fetchCurrencyRates());
  }, [dispatch]);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  // Находим последнюю дату запроса среди всех курсов валют
  const latestRequestDate = rates.reduce((latest, rate) => rate.request_date > latest ? rate.request_date : latest, rates[0]?.request_date);

  // Фильтруем курсы валют для отображения только курсов за последнюю дату запроса
  const latestRates = rates.filter(rate => rate.request_date === latestRequestDate);

  return (
    <div className="currency-rates">
      {latestRates.map((rate, index) => (
        <div key={index} className="currency-rate-block">
          <div>{rate.currency_pair.split(' to ').map(code => countryCodeToFlag(code))} - {rate.request_date}</div>
          <div>Buy: {rate.rate_buy}</div>
          <div>Sell: {rate.rate_sell}</div>
        </div>
      ))}
    </div>
  );
};

export default CurrencyRates;
