// src/actions.js
// Это действия для загрузки курсов валют
export const fetchRatesRequest = () => ({
  type: 'FETCH_RATES_REQUEST',
});

export const fetchRatesSuccess = (rates) => ({
  type: 'FETCH_RATES_SUCCESS',
  payload: rates,
});

export const fetchRatesFailure = (error) => ({
  type: 'FETCH_RATES_FAILURE',
  error,
});

// Асинхронное действие для загрузки данных о курсах валют
export const fetchCurrencyRates = () => async (dispatch) => {
  dispatch(fetchRatesRequest());
  const startDate = "2024-02-28";
  const endDate = new Date().toISOString().split('T')[0];
  try {
    const response = await fetch(`https://osadchiis.site/api/currency_rates?start_date=${startDate}&end_date=${endDate}`);
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    const data = await response.json();
    dispatch(fetchRatesSuccess(data));
  } catch (error) {
    dispatch(fetchRatesFailure(error.toString()));
  }
};
