// reducers/index.js
import { combineReducers } from 'redux';
import currencyRatesReducer from './currencyRatesReducer';

const rootReducer = combineReducers({
  currencyRates: currencyRatesReducer,
  // здесь могут быть другие редьюсеры
});

export default rootReducer;
