//src/App.js
import React, {useState} from 'react';
import './App.css';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import { FiMenu } from 'react-icons/fi';
import CurrencyRates from './CurrencyRates'; // Исправлено на default import


// Header компонент
/**
 * Represents a header component.
 * @constructor
 * @returns {JSX.Element} The rendered header component.
 */
const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
    console.log('Menu state:', isMenuOpen);
  };

  return (
    <header className="header">
      <h1>ТЕСТИРОВНЯ</h1>
      <button className="menu-button" onClick={toggleMenu}>
        <FiMenu size={30} />
      </button>
      <nav className={`nav ${isMenuOpen ? 'show' : ''}`}>
        <Link to="/" onClick={() => setIsMenuOpen(false)}>Главная</Link>
        <Link to="/currency" onClick={() => setIsMenuOpen(false)}>Валюта</Link>
      </nav>
    </header>
  );
};

/**
 * Represents the home page component.
 * @returns {React.JSX.Element} The home page component.
 */
const HomePage = () => (
  <div className="content">
    <h2>Добро пожаловать в ТЕСТИРОВНЯ</h2>
  </div>
);

/**
 * CurrencyPage компонент
 * @constructor
 */
const CurrencyPage = () => (
  <div className="currency-page">
    <h2>Текущие курсы валют</h2>
    <CurrencyRates />
  </div>
);

const App = () => {
    const appRoutes = (
        <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/currency" element={<CurrencyPage />} />
        </Routes>
    );

    return (
        <Router>
            <div className="App">
                <Header />
                {appRoutes}
            </div>
        </Router>
    );
};

export default App;
